import * as React from 'react'
import { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import * as prismicH from '@prismicio/helpers'
import { PrismicRichText } from '@prismicio/react'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import PostItem from '../components/PostItem'
import AdSlot from '../components/AdSlot'
import classNames from 'classnames'

const BlogPage = ({ data }) => {
    const settings = data.settings.data
    const document = data.prismicBlogPage?.data
    const allPosts = data.posts.nodes
    const cats = data.allPrismicBlogCategory.nodes

    const perPage = 32
    const adsPerPosts = 8

    const [list, setList] = useState([...allPosts.slice(0, perPage)])
    const [loadMore, setLoadMore] = useState(false)
    const [hasMore, setHasMore] = useState(allPosts.length > perPage)
    const [cat, setCat] = useState('all')

    const handleLoadMore = () => {
        setLoadMore(true)
    }

    useEffect(() => {
        if (loadMore && hasMore) {
            const currentLength = list.length
            const isMore = currentLength < allPosts.length
            const nextResults = isMore
                ? allPosts.slice(currentLength, currentLength + perPage)
                : []
            setList([...list, ...nextResults])
            setLoadMore(false)
        }
    }, [loadMore, hasMore])

    useEffect(() => {
        const isMore = cat === 'all' && list.length < allPosts.length
        setHasMore(isMore)
    }, [list])

    const handleCatChange = uid => {
        if (uid === 'all') {
            setCat('all')
            setList([...allPosts.slice(0, perPage)])
            setHasMore(allPosts.length > perPage)
        } else {
            const catPosts = data.posts.nodes.filter(
                post => post.data.post_category.uid === uid
            )
            setCat(uid)
            setList(catPosts)
            setHasMore(false)
        }
    }

    return (
        <Layout>
            <Seo
                title={
                    document.seo_title?.text ||
                    prismicH.asText(document.page_title?.richText)
                }
                description={document.seo_description?.text}
                ogImage={document.og_image?.url}
            />
            <div className="bg-gray-100">
                {/* Other Slot */}
                <AdSlot html={data.settings?.data?.other_slot?.text} />
            </div>
            <div className="mx-auto px-6 pt-12 max-w-7xl">
                <div className="mb-6">
                    <h1 className="text-3xl md:text-4xl font-light text-gray-700 leading-snug md:leading-snug">
                        {prismicH.asText(document.page_title?.richText)}
                    </h1>
                </div>
                <div className="pb-20">
                    {document.page_content?.richText ? (
                        <PrismicRichText
                            className="mb-8 prose prose-lg max-w-none text-gray-600"
                            field={document.page_content?.richText}
                            //serializeHyperlink={PrismicLink}
                        />
                    ) : null}

                    <ul className="flex items-center flex-wrap gap-x-4 gap-y-2 mb-10">
                        <li>
                            <button
                                className={classNames(
                                    'inline-block px-2 py-1 text-[10px] font-medium uppercase appearance-none focus:outline-none transition',
                                    {
                                        'text-gray-600 bg-gray-200 hover:bg-gray-300':
                                            cat !== 'all',
                                        'text-white bg-primary': cat === 'all'
                                    }
                                )}
                                onClick={() => handleCatChange('all')}
                                title="Toutes les catégories"
                            >
                                Toutes les catégories
                            </button>
                        </li>
                        {cats.map(({ uid, data }) => (
                            <li key={uid}>
                                <button
                                    className={classNames(
                                        'inline-block px-2 py-1 text-[10px] font-medium uppercase appearance-none focus:outline-none transition',
                                        {
                                            'text-gray-600 bg-gray-200 hover:bg-gray-300':
                                                cat !== uid,
                                            'text-white bg-primary': cat === uid
                                        }
                                    )}
                                    onClick={() => handleCatChange(uid)}
                                    title={data.category_title?.text}
                                >
                                    {data.category_title?.text}
                                </button>
                            </li>
                        ))}
                    </ul>

                    <div className="md:grid md:grid-cols-2 lg:grid-cols-4 gap-6 sm:gap-8 md:gap-12 mb-12">
                        {list.map((post, i) => (
                            <React.Fragment key={i}>
                                {i > 0 && i % adsPerPosts === 0 ? (
                                    <div className="md:col-span-2 lg:col-span-4">
                                        <AdSlot
                                            html={
                                                data.settings?.data?.other_slot
                                                    ?.text
                                            }
                                        />
                                    </div>
                                ) : null}
                                <PostItem
                                    settings={settings}
                                    data={data}
                                    post={post}
                                    key={post.uid}
                                />
                            </React.Fragment>
                        ))}
                    </div>

                    <div className="flex items-center justify-center">
                        {hasMore ? (
                            <button
                                className="inline-block px-4 py-2 text-sm font-medium rounded-md bg-gray-200 hover:bg-gray-300 transition"
                                onClick={handleLoadMore}
                                title="Voir plus d'articles"
                            >
                                Voir plus d'articles…
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default BlogPage

export const query = graphql`
    query BlogQuery {
        settings: prismicGeneralSettings {
            data {
                site_name {
                    text
                }
                other_slot {
                    text
                }
            }
        }
        prismicBlogPage {
            uid
            data {
                page_title {
                    richText
                }
                page_content {
                    richText
                }
                seo_title {
                    text
                }
                seo_description {
                    text
                }
                og_image {
                    url
                }
            }
        }
        allPrismicBlogCategory {
            nodes {
                uid
                data {
                    category_title {
                        text
                    }
                }
            }
        }
        posts: allPrismicPost(
            sort: { fields: first_publication_date, order: DESC }
        ) {
            nodes {
                uid
                first_publication_date(formatString: "MMMM Do, YYYY")
                data {
                    og_image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    quality: 100
                                    placeholder: BLURRED
                                )
                            }
                        }
                    }
                    post_title_alt {
                        richText
                    }
                    post_title {
                        richText
                    }
                    post_link {
                        link_type
                        type
                        uid
                        url
                    }
                    post_category {
                        uid
                        document {
                            ... on PrismicBlogCategory {
                                id
                                data {
                                    category_title {
                                        text
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
